import {
    Timestamp,
    doc,
    getDoc,
    increment,
    setDoc,
    updateDoc,
} from "firebase/firestore";

import { categoriesIdsOrdered, sortItemsByCategory } from "categories";
import { generateId } from "utils";
import { DEFAULT_CATEGORIES_ORDER, PRIMARY_LIST_NAME } from "../constants";
import {
    LISTS_COLLECTION,
    USERS_COLLECTION,
    addItems,
    db,
} from "../firebase.js";
import {
    ADDED_FROM,
    Item,
    LIST_TYPES,
    ListData,
    PartialListConfig,
    UserData,
} from "../types";

export const addSavedItemsToList = async ({
    listId,
    userId,
    selectedSavedItems,
}: {
    listId: string;
    userId: string;
    selectedSavedItems?: Array<Item>;
}) => {
    try {
        const listData = await getDoc(doc(db, LISTS_COLLECTION, listId));

        if (!listData.exists() || !selectedSavedItems) {
            return false;
        }

        const { items: existingItems } = listData.data() as ListData;

        const addedAt = Timestamp.now();
        const addedBy = userId;
        const addedFrom = ADDED_FROM.webapp;

        // const existingItemsWithoutSaved = existingItems.filter(
        //     (item) => !item.saved
        // );

        const existingItemsIds = existingItems.map(({ text }) => text);

        const itemsToAdd = selectedSavedItems.reduce(
            (acc, savedItem) =>
                existingItemsIds.includes(savedItem.text)
                    ? acc
                    : acc.concat({
                          ...savedItem,
                          addedAt,
                          addedBy,
                          addedFrom,
                          checked: false,
                          missing: false,
                      }),
            [] as Item[]
        );

        await updateDoc(doc(db, LISTS_COLLECTION, listId), {
            items: existingItems.concat(itemsToAdd),
        });
        return true;
    } catch (error) {
        console.error(error);
        console.error("error adding saved item");
        return false;
    }
};

export const addItemToList = async ({
    userId,
    listId,
    itemsText,
    token,
}: {
    userId: string;
    listId: string;
    itemsText: string;
    token: string;
}) => {
    try {
        const res = await addItems({
            userId,
            listId,
            itemsText,
            token,
        });

        return true;
        // const newItem = {
        //     text: itemsText,
        //     checked: false,
        //     categoryId,
        //     originalText: itemsText,
        //     addedAt: Timestamp.now(),
        //     addedBy: userId as string,
        //     addedFrom: ADDED_FROM.webapp,
        //     quantity: null,
        //     unit: null,
        //     comments: null,
        //     missing: false,
        //     saved: false,
        // };

        // await updateDoc(doc(db, LISTS_COLLECTION, listId), {
        //     items: arrayUnion(newItem),
        // });
        // return true;
    } catch (error) {
        console.error(error);
        console.error("error adding item");
        return false;
    }
};

export const updateItemInDB = async ({
    listId,
    itemId,
    updates,
    existingItems,
    existingSavedItems,
}: {
    listId: string;
    itemId: string;
    updates: Partial<Item>;
    existingItems: Array<Item>;
    existingSavedItems: Array<Item>;
}) => {
    try {
        const updatedItems = existingItems.map((item) => {
            if (item.text === itemId) {
                return {
                    ...item,
                    ...updates,
                };
            }
            return item;
        });

        const updatedSavedItems = existingSavedItems.reduce(
            (acc: Array<Item>, savedItem) => {
                const removeFromSaved =
                    savedItem.text === itemId && updates.saved === false;
                if (removeFromSaved) {
                    return acc;
                }
                // const updateInSaved =
                //     savedItem.text === itemId && updates.saved;
                // if (updateInSaved) {

                //     return acc.concat({ ...savedItem, ...updates });
                // }
                return acc.concat(savedItem);
            },
            []
        );

        const notInSavedAndShouldBe =
            updates.saved &&
            !existingSavedItems.find((item) => item.text === itemId);

        if (notInSavedAndShouldBe) {
            const existingItem = existingItems.find(
                (item) => item.text === itemId
            );
            const newItem =
                existingItem &&
                ({ ...existingItem, ...updates, checked: false } as Item);
            if (newItem) {
                updatedSavedItems.push(newItem);
            }
        }

        updateList({
            listId,
            updates: {
                items: updatedItems,
                savedItems: updatedSavedItems,
            },
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateSavedItemInDB = async ({
    listId,
    itemId,
    updates,
    existingSavedItems,
}: {
    listId: string;
    itemId: string;
    updates: Partial<Item>;
    existingSavedItems: Array<Item>;
}) => {
    try {
        const updatedSavedItems = existingSavedItems.map((item) => {
            if (item.text === itemId) {
                return {
                    ...item,
                    ...updates,
                };
            }
            return item;
        });

        updateList({
            listId,
            updates: {
                savedItems: updatedSavedItems,
            },
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteItemFromDB = async ({
    listId,
    itemId,
    existingItems,
}: {
    listId: string;
    itemId: string;
    existingItems: Array<Item>;
}) => {
    try {
        const updatedItems = existingItems.filter(
            (item) => item.text !== itemId
        );
        updateList({
            listId,
            updates: {
                items: updatedItems,
            },
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteSavedItemFromDB = async ({
    listId,
    itemId,
    existingSavedItems,
    existingItems,
}: {
    listId: string;
    itemId: string;
    existingSavedItems: Array<Item>;
    existingItems: Array<Item>;
}) => {
    try {
        const updatedItems = existingSavedItems.filter(
            (item) => item.text !== itemId
        );

        let updates = {
            savedItems: updatedItems,
        } as {
            savedItems: Array<Item>;
            items?: Array<Item>;
        };

        // unmark as saved if item is in list
        const itemInList = existingItems.find((item) => item.text === itemId);
        if (itemInList) {
            updates["items"] = existingItems.map((item) =>
                item.text === itemId ? { ...item, saved: false } : item
            );
        }

        updateList({
            listId,
            updates,
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteAllItemsFromDB = async ({ listId }: { listId: string }) => {
    try {
        const groupRef = doc(db, LISTS_COLLECTION, listId);
        await updateDoc(groupRef, {
            items: [],
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const markAllItemsAsNotMissing = async ({
    listId,
    existingItems,
}: {
    listId: string;
    existingItems: Array<Item>;
}) => {
    try {
        const updatedItems = existingItems.map((item) => ({
            ...item,
            missing: false,
        }));
        updateList({
            listId,
            updates: {
                items: updatedItems,
            },
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const deleteAllCheckedItems = async ({
    listId,
    existingItems,
}: {
    listId: string;
    existingItems: Array<Item>;
}) => {
    try {
        const updatedItems = existingItems.filter((item) => !item.checked);
        updateList({
            listId,
            updates: {
                items: updatedItems,
            },
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateList = async ({
    listId,
    updates,
}: {
    listId: string;
    updates: Partial<ListData>;
}) => {
    try {
        const groupRef = doc(db, LISTS_COLLECTION, listId);
        await updateDoc(groupRef, {
            ...updates,
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateUser = async ({
    userId,
    updates,
}: {
    userId: string;
    updates:
        | { [key: string]: string | number | boolean | null | Timestamp }
        | (Partial<UserData> & { lastListNameChange?: Timestamp }); // lastListNameChange to cause rerender
}) => {
    try {
        const groupRef = doc(db, USERS_COLLECTION, userId);
        await updateDoc(groupRef, {
            ...updates,
        });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateCategroyOrder = async ({
    categeoryToChange,
    oldIndex,
    newIndex,
    listId,
}: {
    categeoryToChange: string;
    oldIndex: number;
    newIndex: number;
    listId: string;
}) => {
    try {
        const listRef = doc(db, LISTS_COLLECTION, listId);
        const listDoc = await getDoc(listRef);
        if (listDoc.exists()) {
            const { categoriesSortOrder } = listDoc.data() as ListData;
            const newCategoriesOrder = [...categoriesSortOrder];
            newCategoriesOrder.splice(oldIndex, 1);
            newCategoriesOrder.splice(newIndex, 0, categeoryToChange);
            await updateDoc(listRef, {
                categoriesSortOrder: newCategoriesOrder,
            });
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updatePartialList = async ({
    listId,
    updates,
}: {
    listId: string;
    updates: Partial<ListData>;
}) => {
    updates["changedSplitOrder"] = true;
    try {
        return updateList({
            listId,
            updates,
        });
    } catch (error) {
        console.error(error);
        return null;
    }
};

// export const removeParticipantFromList = async ({
//     //! make common cloud function
//     listId,
//     participantId,
//     listType,
// }: {
//     listId: string;
//     participantId: string;
//     listType: LIST_TYPES;
// }) => {
//     try {
//         // participant is not the owner
//         const groupRef = doc(db, LISTS_COLLECTION, listId);
//         await updateDoc(groupRef, {
//             participants: arrayRemove(participantId),
//         });

//         const { lists } = (await getUserData({
//             userId: participantId,
//         })) as UserData;

//         const newLists = lists.filter((list) => list !== listId);

//         if (listType === LIST_TYPES.primary) {
//             const newPrimaryListId = await createNewList({
//                 userId: participantId,
//                 listType: LIST_TYPES.primary,
//             });
//             newLists.unshift(newPrimaryListId);
//         }

//         await updateDoc(doc(db, USERS_COLLECTION, participantId), {
//             lists: newLists,
//             currentListId: newLists[0],
//         });

//         return true;
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// };

export const getUserData = async ({ userId }: { userId: string }) => {
    try {
        const userRef = doc(db, USERS_COLLECTION, userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            return { ...userDoc.data(), userId: userDoc.id } as UserData & {
                userId: string;
            };
        }
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getListData = async ({ listId }: { listId: string }) => {
    try {
        const listRef = doc(db, LISTS_COLLECTION, listId);
        const listDoc = await getDoc(listRef);
        if (listDoc.exists()) {
            return { ...listDoc.data(), listId: listId } as ListData & {
                listId: string;
            };
        }
        return null;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const createNewList = async ({
    userId,
    listType = LIST_TYPES.primary,
    isUserPremium = false,
}: {
    userId: string;
    listType?: LIST_TYPES;
    isUserPremium?: boolean;
}): Promise<string> => {
    const listId = generateId();
    await setDoc(doc(db, LISTS_COLLECTION, listId), {
        created_by: userId,
        created_at: new Date(),
        items: [],
        savedItems: [],
        participants: [userId],
        active: true,
        name:
            listType === LIST_TYPES.primary ? PRIMARY_LIST_NAME : "רשימה חדשה",
        type: listType,
        splitMode: false,
        partialListsConfig: null,
        partOfPremium: isUserPremium, // at least one of the participants is premium
        categoriesSortOrder: DEFAULT_CATEGORIES_ORDER,
    });
    return listId;
};

// export const deleteListFromUser = async ({
//     userId,
//     listId,
// }: {
//     userId: string;
//     listId: string;
// }) => {
//     try {
//         const listData = await getListData({ listId });
//         if (!listData) {
//             return null;
//         }

//         // this is not a primary list :)

//         const { participants } = listData;
//         await Promise.all(
//             participants.map(async (participantId) => {
//                 const participant = await getUserData({
//                     userId: participantId,
//                 });
//                 if (!participant) {
//                     return null;
//                 }
//                 const { lists, currentListId } = participant;
//                 const newLists = lists.filter((list) => list !== listId);
//                 const newCurrentListId =
//                     currentListId === listId ? newLists[0] : currentListId;
//                 await updateDoc(doc(db, USERS_COLLECTION, participantId), {
//                     lists: newLists,
//                     currentListId: newCurrentListId,
//                 });
//             })
//         );

//         await deleteDoc(doc(db, LISTS_COLLECTION, listId));

//         return true;
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// };

// split

export const activateSplitMode = async ({
    listId,
    currentSplitMode,
}: {
    listId: string;
    currentSplitMode: boolean;
}) => {
    try {
        if (currentSplitMode) {
            // deactivate split mode
            await updateList({
                listId,
                updates: {
                    splitMode: false,
                },
            });
        } else {
            const listData = await getListData({ listId });
            if (!listData) {
                return null;
            }

            const {
                partialListsConfig,
                items,
                participants,
                categoriesSortOrder,
                changedSplitOrder,
            } = listData;
            const updatedSplitConfig = getUpdatedSplitConfig({
                currentSplitConfig: partialListsConfig,
                items,
                participants: participants,
                categoriesSortOrder,
                changedSplitOrder,
            });

            await updateList({
                listId,
                updates: {
                    splitMode: true,
                    partialListsConfig: updatedSplitConfig,
                },
            });
        }
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getUpdatedSplitConfig = ({
    currentSplitConfig,
    items,
    participants,
    categoriesSortOrder,
    changedSplitOrder = false,
}: {
    currentSplitConfig: Array<PartialListConfig> | null;
    items: Array<Item>;
    participants: Array<string>;
    categoriesSortOrder: Array<string>;
    changedSplitOrder: boolean | undefined;
}): Array<PartialListConfig> => {
    let splittingConfig: Array<PartialListConfig>;

    if (false && changedSplitOrder && currentSplitConfig) {
        // disable for now
        // splittingConfig = currentSplitConfig;
    } else {
        const sortedItems = sortItemsByCategory({
            items,
            categoriesSortOrder,
        });

        const uncheckedItems = sortedItems.filter((item) => !item.checked);

        const itemsGroupedByCategory = uncheckedItems.reduce(
            (acc: { [key: string]: number }, item) => {
                const { categoryId } = item;
                if (!acc[categoryId]) {
                    acc[categoryId] = 0;
                }
                acc[categoryId]++;
                return acc;
            },
            {}
        );

        const firstHalfCategories = Object.entries(
            itemsGroupedByCategory
        ).reduce(
            (
                acc: { categories: Array<string>; items: number },
                [categoryId, count]
            ) => {
                if (acc.items + count <= uncheckedItems.length / 2) {
                    acc.categories.push(categoryId);
                    acc.items += count;
                    return acc;
                }
                return acc;
            },
            {
                categories: [],
                items: 0,
            }
        ).categories;

        const secondHalfCategories = Object.keys(itemsGroupedByCategory).reduce(
            (acc: Array<string>, categoryId) => {
                return firstHalfCategories.includes(categoryId)
                    ? acc
                    : acc.concat(categoryId);
            },
            []
        );

        splittingConfig = [
            {
                categories: firstHalfCategories,
                assignees: [] as Array<string>, // will be add later
            },
            {
                categories: secondHalfCategories,
                assignees: [] as Array<string>, // will be add later
            },
        ];
    }

    const categoriesMissingInConfig = categoriesIdsOrdered.filter((id) =>
        splittingConfig.every(({ categories }) => !categories.includes(id))
    );

    const firstHalfMissingCategories = categoriesMissingInConfig.slice(
        0,
        categoriesMissingInConfig.length / 2
    );
    const secondHalfMissingCategories = categoriesMissingInConfig.slice(
        categoriesMissingInConfig.length / 2
    );

    splittingConfig[0].categories = splittingConfig[0].categories.concat(
        firstHalfMissingCategories
    );
    splittingConfig[1].categories = splittingConfig[1].categories.concat(
        secondHalfMissingCategories
    );

    // here all the cats exists in the config, preffered by what the user has chosen

    // const activeCategories = items.reduce((acc: Array<string>, item) => {
    //   if (!acc.includes(item.categoryId)) {
    //     return acc.concat(item.categoryId);
    //   }
    //   return acc;
    // }, []);

    // const activeIn1 = splittingConfig[0].categories.filter((category) => activeCategories.includes(category));

    // const activeIn2 = splittingConfig[1].categories.filter((category) => activeCategories.includes(category));

    // const activeIn1Length = activeIn1.length;
    // const activeIn2Length = activeIn2.length;

    // const activeDiff1to2 = activeIn1Length - activeIn2Length;

    // if (activeDiff1to2 > 1) {
    //   const catsNumToMove = Math.floor(activeDiff1to2 / 2);
    //   const categoriesToMove = activeIn1.slice(-catsNumToMove);
    //   splittingConfig[0].categories = splittingConfig[0].categories.filter(
    //     (category) => !categoriesToMove.includes(category),
    //   );
    //   splittingConfig[1].categories = splittingConfig[1].categories.concat(categoriesToMove);
    // }

    // const activeDiff12to1 = activeIn2Length - activeIn1Length;

    // if (activeDiff12to1 > 1) {
    //   const catsNumToMove = Math.floor(activeDiff12to1 / 2);
    //   const categoriesToMove = activeIn2.slice(-catsNumToMove);
    //   splittingConfig[1].categories = splittingConfig[1].categories.filter(
    //     (category) => !categoriesToMove.includes(category),
    //   );
    //   splittingConfig[0].categories = splittingConfig[0].categories.concat(categoriesToMove);
    // }
    // add missing participants to partialListsConfig if needed
    const participantsMissingInConfig = participants.filter(
        (participant) =>
            !splittingConfig.some(({ assignees }) =>
                assignees.includes(participant)
            )
    );

    const fitstHalfMissingParticipants = participantsMissingInConfig.slice(
        0,
        participantsMissingInConfig.length / 2
    );
    const secondHalfMissingParticipants = participantsMissingInConfig.slice(
        participantsMissingInConfig.length / 2
    );

    splittingConfig[0].assignees = splittingConfig[0].assignees
        .concat(fitstHalfMissingParticipants)
        .filter((participant) => participants.includes(participant));
    splittingConfig[1].assignees = splittingConfig[1].assignees
        .concat(secondHalfMissingParticipants)
        .filter((participant) => participants.includes(participant));

    return splittingConfig;
};

export const voteForCategory = ({
    itemId,
    categoryId,
}: {
    itemId: string;
    categoryId: string;
}) => {
    try {
        setDoc(
            doc(db, "categories-votes", itemId),
            {
                [categoryId]: { count: increment(1) },
            },
            { merge: true }
        );
    } catch (error) {
        console.error(error);
        return null;
    }
};
