import { BOT_PHONE_NUMBER } from "../constants";

export default function ErrorFallback() {
    return (
        <div className="grid place-content-center h-[50vh]">
            <div className="flex flex-col gap-5 justify-center items-center text-center">
                <h1 className="text-3xl font-medium ">שגיאה בהתחברות</h1>
                <p className="text-xl">
                    יש להיכנס שוב דרך הקישור שקיבלתם בוואטסאפ
                </p>
                <a
                    href={encodeURI(
                        `https://wa.me/${BOT_PHONE_NUMBER}?text=אתר הרשימה`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-primary w-full mt-5"
                >
                    מעבר לבוט
                </a>
            </div>
        </div>
    );
}
