import { BOT_PHONE_NUMBER, registerToBotURL } from "../constants";

export default function LoginPage() {
    return (
        <div className="mt-32 flex flex-col gap-20 max-w-[400px] mx-auto">
            <div className="text-slate-800 text-5xl font-semibold text-center">
                <h1 className="font-poppins text-6xl">Groceroo</h1>
                התחברות
            </div>
            <div className="flex flex-col gap-5 justify-center items-center text-center">
                <h2 className="text-3xl font-medium">
                    להתחברות יש להיכנס דרך הקישור שקיבלתם בוואטסאפ
                </h2>
                <a
                    href={encodeURI(
                        `https://wa.me/${BOT_PHONE_NUMBER}?text=אתר הרשימה`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-primary-lg w-full mt-5"
                >
                    מעבר לבוט לקבלת קישור
                </a>
            </div>
            <div className="flex gap-2 justify-center items-center text-center text-2xl lg:text-2xl font-medium">
                <h3>חיפשתם איך מצטרפים לבוט?</h3>
                <a
                    href={registerToBotURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline text-green-500"
                >
                    לחצו כאן
                </a>
            </div>
        </div>
    );
}
