import {
    AdjustmentsVerticalIcon,
    BookmarkIcon,
    PencilIcon,
    ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import Link from "components/Link";
import { ListSettingsMenu } from "components/list/ListMenu";
import useIsIphoneAndPWA from "hooks/useIsIphoneAndPWA";
import useListData, { ListDataExtended } from "hooks/useListData";
import { createContext, useState } from "react";

type ListContextType = {
    listData: ListDataExtended | undefined;
    loading: boolean;
    error: unknown;
};

export const ListContext = createContext<ListContextType>({
    listData: undefined,
    loading: false,
    error: null,
});

export default function ListCXT({
    listId,
    children,
}: {
    listId: string;
    children: React.ReactNode;
}) {
    const { listData: value, loading, error } = useListData({ listId });
    const [openMenu, setOpenMenu] = useState(false);

    // const [listData, setListData] = useState<ListDataExtended | undefined>(
    //     undefined
    // );

    // useEffect(() => {
    //     if (!value) return;
    //     // return;
    //     console.log("value", value);
    //     // setListData({ ...value, id: listId } as ListDataExtended);
    //     const { savedItems, items } = value as ListDataExtended;
    //     console.log("savedItems", savedItems, value);
    //     const updatedItems: Array<Item> = items;

    //     for (const savedItem of savedItems) {
    //         const existingItemIndex = items.findIndex(
    //             (i) => i.text === savedItem.text
    //         );
    //         if (existingItemIndex !== -1) {
    //             const existingItemInList = items[existingItemIndex];
    //             updatedItems[existingItemIndex] = {
    //                 ...existingItemInList,
    //                 saved: true,
    //             };
    //         }
    //     }

    //     const allItemsAreUpToDate = savedItems.every((savedItem) => {
    //         const existingItem = items.find((i) => i.text === savedItem.text);
    //         if (!existingItem) return true;
    //         return existingItem.saved;
    //     });

    //     if (listData && allItemsAreUpToDate) {
    //         console.log("allItemsAreUpToDate");
    //         return;
    //     }

    //     setListData({ ...value, items: updatedItems } as ListDataExtended);
    // }, [value, listData, listId]);

    return (
        <ListContext.Provider value={{ listData: value, loading, error }}>
            {children}
            <>
                <ListBottomNavbar {...{ listId, setOpenMenu }} />
                <ListSettingsMenu {...{ openMenu, setOpenMenu }} />
            </>
        </ListContext.Provider>
    );
}

export const ListBottomNavbar = ({
    listId,
    setOpenMenu,
}: {
    listId: string | null;
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const isIphoneAndPWA = useIsIphoneAndPWA();
    return (
        <div
            className={`z-20 bg-white ${
                isIphoneAndPWA ? "pt-2 pb-10 h-24" : "py-2 h-16"
            } shadow-[0_35px_60px_-5px_rgba(0,0,0,0.4)] fixed bottom-0 left-0 w-full flex flex-row-reverse divide-x divide-slate-100 justify-between px-2 xl:px-40 2xl:px-60`}
        >
            {[
                {
                    title: "רשימה",
                    icon: (
                        <ShoppingCartIcon className="w-5 h-5 group-[.active]:fill-green-500" />
                    ),
                    href: `/list/${listId}`,
                },
                {
                    title: "עריכה",
                    icon: (
                        <PencilIcon className="w-5 h-5 group-[.active]:fill-orange-300" />
                    ),
                    href: `/list/${listId}/edit`,
                },
                {
                    title: "שמורים",
                    icon: (
                        <BookmarkIcon className="w-5 h-5 group-[.active]:fill-yellow-300" />
                    ),
                    href: `/list/${listId}/saved-items`,
                },
                {
                    title: "פעולות",
                    icon: (
                        <AdjustmentsVerticalIcon className="w-5 h-5 group-[.active]:fill-blue-500" />
                    ),
                    // href: `/lists`,
                    onClick: () => setOpenMenu(true),
                },
            ]
                .reverse()
                .map(({ title, icon, href, onClick }) => {
                    const isActive =
                        window.location.pathname === href ||
                        (title === "עריכה" &&
                            window.location.pathname.includes("edit")) ||
                        (title === "שמורים" &&
                            window.location.pathname.includes("saved-items")) ||
                        (title === "פעולות" &&
                            (window.location.pathname.includes(
                                "participants"
                            ) ||
                                window.location.pathname.includes("split") ||
                                window.location.pathname.includes(
                                    "category-order"
                                )));
                    return (
                        <Link
                            key={title}
                            to={href}
                            onClick={() => {
                                onClick && onClick();
                                window.scrollTo(0, 0);
                            }}
                            disabled={listId === null}
                            className={`${
                                isActive
                                    ? "bg-green-50 rounded-full font-semibold active"
                                    : ""
                            } flex-1 flex flex-col gap-0.5 text-center items-center justify-center`}
                        >
                            <span
                                className={`${isActive ? "group active" : ""}`}
                            >
                                {icon}
                            </span>
                            <span className="text-sm">{title}</span>
                        </Link>
                    );
                })}
        </div>
    );
};
