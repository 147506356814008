import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { BoltIcon } from "@heroicons/react/24/solid";
import splitIcon from "assets/images/split icon.png";
import UserCXT from "context/UserContext";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { isPremium } from "utils";
import Drawer from "./Drawer";
import Link from "./Link";
import Logo from "./Logo";
import ListProgressBar from "./list/ListProgressBar";
import useConfirm from "hooks/useConfirm";
import { Color } from "context/ConfirmContextProvider";
import { getListData, updateList } from "db-utils";
import useListIdFromParams from "hooks/useListIdFromParams";
import { Timestamp } from "firebase/firestore";
import { botConversationStarterLink } from "../constants";
import useAlert from "hooks/useAlert";

export default function Navbar({
    showOnList = false,
}: {
    showOnList?: boolean;
}) {
    const location = useLocation();
    const isListPage = location.pathname.includes("/list/");
    const isListsPage = location.pathname.includes("/lists");
    const isAccountPage = location.pathname.includes("/account");

    const showSideMenu = isListsPage || isAccountPage;

    if (isListPage && !showOnList) return null;

    return (
        <UserCXT>
            <nav
                dir="rtl"
                className="font-assistant relative z-30 px-3 lg:px-10 py-1 flex flex-row-reverse justify-between items-center top-0 left-0 h-14 w-screen"
            >
                <div className="absolute -right-60 -top-[15rem] h-60 -z-10 w-[36rem] transform-gpu md:right-0 bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#64e843a3] from-[28%] via-[#53da67bb] via-[70%] to-[#60f060] rotate-[-10deg] rounded-full blur-3xl"></div>
                <Link outsideUserContext to="/">
                    <Logo />
                </Link>
                {showSideMenu ? (
                    <SideMenuDrawer buttonStyle="text-slate-700 w-7 h-7" />
                ) : null}
            </nav>
        </UserCXT>
    );
}

export const ListNavbar = ({
    listProgress,
    isEditMode,
    isSavedItems,
    listName,
    showSplitMode = false,
    showActionsMenu = true,
    hasFinished,
    button,
}: {
    listProgress?: number;
    isEditMode?: boolean;
    isSavedItems?: boolean;
    listName: string;
    showSplitMode?: boolean;
    showActionsMenu?: boolean;
    button?: JSX.Element;
    hasFinished?: boolean;
}) => {
    const { user } = useUser();
    const { listId } = useListIdFromParams();

    const isUserPremium = isPremium({
        type: user?.userData?.accountType,
    });
    const [hasFinishedBuying, setHasFinishedBuying] = useState<boolean>(false);

    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();

    const onFinishedClick = async () => {
        if (!listId) return;
        if (
            await isConfirmed(
                `פעולה זו תמחק את כל הפריטים המסומנים ובנוסף תחזיר את הפריטים החסרים בחזרה לרשימה`,
                Color.success
            )
        ) {
            const listData = await getListData({ listId });
            if (!listData) return;
            const { items: existingItems, previousLists } = listData;

            const updatedItems = existingItems
                .filter((item) => !item.checked)
                .map((item) => ({ ...item, missing: false }));

            await updateList({
                listId,
                updates: {
                    items: updatedItems,
                    previousLists: previousLists
                        ? previousLists.concat({
                              items: existingItems,
                              finishedAt: Timestamp.now(),
                          })
                        : [
                              {
                                  items: existingItems,
                                  finishedAt: Timestamp.now(),
                              },
                          ],
                },
            });
            await isAlerted(
                `בוצע.\nאפשר לקבל סיכום קנייה מהבוט ע"י שליחת "סיכום קנייה" בצ'אט`
            );
        }
        setHasFinishedBuying(true);
    };

    return (
        <nav
            dir="rtl"
            className={`font-assistant fixed z-20 pb-3 pt-6 px-3 flex flex-col gap-5 top-0 left-0 ${
                isSavedItems ? "h-20" : "justify-center h-24"
            } w-screen bg-white lg:px-60`}
        >
            <div className="flex justify-between items-center w-full">
                <div className="flex gap-3 items-center">
                    <SideMenuDrawer />
                    <div className="flex gap-1 items-center">
                        <h6 className="text-[1.35rem] font-medium">
                            {listName}
                        </h6>
                        {isEditMode && button ? button : null}
                    </div>
                    {showSplitMode ? (
                        <Link to={`split`}>
                            <img
                                src={splitIcon}
                                alt="split"
                                className="w-5 h-5 mr-2"
                            />
                        </Link>
                    ) : null}
                </div>
                <div className="flex gap-2 items-center">
                    {hasFinished && !showSplitMode ? (
                        <button
                            className="btn-primary"
                            onClick={onFinishedClick}
                        >
                            סיימתי
                        </button>
                    ) : hasFinishedBuying && !showSplitMode ? (
                        <Link
                            className="btn-primary"
                            external
                            to={`${botConversationStarterLink}${encodeURI(
                                "סיכום קניה"
                            )}`}
                        >
                            סיכום קניה
                        </Link>
                    ) : null}
                    {isUserPremium ? null : (
                        <Link
                            outsideUserContext
                            to={`/premium-info/?phone=${user?.userId}`}
                            className="text-2xl relative"
                        >
                            <BoltIcon className="h-9 p-1 w-9 text-amber-300" />
                            <span className="text-xs text-center w-9 font-semibold absolute -bottom-2.5 left-0">
                                שדרג
                            </span>
                        </Link>
                    )}
                    {/* <ListSettingsMenu /> */}
                    {/* <ListActionsMenu
                        isEditing={isEditing}
                        show={showActionsMenu}
                    /> */}
                </div>
            </div>
            {listProgress !== undefined && !isNaN(listProgress) ? (
                <ListProgressBar progress={listProgress} />
            ) : null}
        </nav>
    );
};

export const SideMenuDrawer = ({
    buttonStyle = "h-7 w-7 text-slate-800",
}: {
    buttonStyle?: string;
}) => {
    const { user } = useUser();

    const [openMenu, setOpenMenu] = useState<boolean>(false);

    if (!user) return null;

    const routes = [
        {
            title: "הרשימות שלי",
            href: `/lists/`,
        },
        {
            title: (
                <span dir="">
                    Groceroo Plus{" "}
                    <BoltIcon className="h-5 w-5 inline-block text-amber-300" />
                </span>
            ),
            href: `/premium-info/?phone=${user.userId}`,
            outsideUserContext: true,
        },
        {
            title: "החשבון שלי",
            href: "/account",
        },
        {
            title: "יצירת קשר",
            href: "https://wa.me/972587501168",
            external: true,
        },
    ];

    const isPremiumUser = isPremium({
        type: user.userData?.accountType,
    });
    return (
        <div className="flex items-center">
            <button onClick={() => setOpenMenu(true)}>
                <Bars3Icon className={buttonStyle} />
            </button>
            <Drawer
                open={openMenu}
                setOpen={setOpenMenu}
                onClose={() => setOpenMenu(false)}
                onOpen={() => {}}
                anchor="right"
                className="h-screen w-[80vw] lg:w-[40vw] z-40"
                disableSwipeToOpen
            >
                <div className="w-full pt-3 flex justify-between">
                    <Link
                        className="w-full"
                        to="/"
                        onClick={() => setOpenMenu(false)}
                    >
                        <Logo />
                    </Link>
                    <button
                        type="button"
                        onClick={() => setOpenMenu(false)}
                        className="flex w-full justify-end p-1"
                    >
                        <XMarkIcon className="h-7 w-7 text-slate-800" />
                    </button>
                </div>
                <div className="flex flex-col justify-between h-[85vh]">
                    <ul className="w-full flex flex-col justify-start gap-0 divide-y divide-slate-300 text-2xl pt-10">
                        {routes.map(
                            ({ title, href, outsideUserContext, external }) => {
                                const isAllHebrewChars =
                                    typeof title === "string" &&
                                    /^[\u0590-\u05FF\s]+$/.test(title);

                                return (
                                    <li
                                        key={title.toString()}
                                        className={`${
                                            isAllHebrewChars
                                                ? ""
                                                : "font-poppins"
                                        } flex justify-between w-full items-center px-2 py-4`}
                                    >
                                        {external ? (
                                            <a
                                                href={href}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="w-full"
                                                onClick={() =>
                                                    setOpenMenu(false)
                                                }
                                            >
                                                {title}
                                            </a>
                                        ) : (
                                            <Link
                                                outsideUserContext={
                                                    outsideUserContext
                                                }
                                                onClick={() =>
                                                    setOpenMenu(false)
                                                }
                                                to={href}
                                                className="w-full"
                                            >
                                                {title}
                                            </Link>
                                        )}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                    {isPremiumUser ? (
                        <Link
                            onClick={() => setOpenMenu(false)}
                            to={routes[1].href}
                            className="flex w-48 mx-auto items-center justify-center gap-2 text-lg mt-40 text-green-500  rounded-full py-1.5 px-2 text-center"
                        >
                            <span>
                                {isPremiumUser ? "משתמש " : "שדרג ל"} Plus{" "}
                                <BoltIcon className="h-5 w-5 inline-block text-amber-300" />
                            </span>
                        </Link>
                    ) : null}
                </div>
            </Drawer>
        </div>
    );
};
