import Helmet from "react-helmet";

export default function Accessibility() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://groceroo.co.il/accessibility" />
            </Helmet>
            <p dir="rtl">
                <span>הצהרת נגישות </span>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <span>אפליקציית, אתר וצ'אטבוט </span>
                <span dir="ltr">
                    <span dir="ltr"></span>Groceroo
                </span>
                <span>
                    <span dir="rtl"></span>{" "}
                </span>
                <span>
                    הינם למטרת שירותים/מוצרים בתחום התוכנה והפרודקטיביות.
                </span>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <span>
                    בהצהרה זו מטרתנו לייעל את השימוש ולשפר את השירות שלנו בכל
                    הנוגע לנגישות ושוויון זכויות לאנשים בעלי מוגבלויות.
                </span>
            </p>
            <p dir="rtl">
                <span>
                    התאמת הנגישות שלנו בוצעה בהתאם לתקנה 35 בתקנות שוויון זכויות
                    לאנשים עם מוגבלות (התאמות נגישות לשירות) התשע"ג 2013 לרמה{" "}
                </span>
                <span dir="ltr">
                    <span dir="ltr"></span>AA
                </span>
                <span>
                    <span dir="rtl"></span> בכפוף לשינויים והתאמות שבוצעו במסמך
                    התקן הישראלי.
                </span>
            </p>
            <p dir="rtl">
                <span>
                    התאמת הנגישות נבדקה בדפדפנים כרום, פיירפוקס, ספארי, מוזילה
                    ואדג'.{" "}
                </span>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <strong>
                    <span>אמצעי נגישות הקיימים באתר:</span>
                </strong>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>תמיכה בכל הדפדפנים התקניים המקובלים (כמו </span>
                <span dir="ltr">
                    <span dir="ltr"></span>Chrome, Explorer, FireFox, Opera,
                    Mozila
                </span>
                <span>
                    <span dir="rtl"></span>).
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    תכני האתר נכתבו בשפה ברורה ונעשה שימוש בפונטים קריאים
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>מבניות האתר בנויה מכותרות, פסקאות ורשימות</span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    התמצאות באתר היא פשוטה ונוחה וכוללת תפריטים זמינים וברורים
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    הקישורים באתר ברורים ומסבירים להיכן מועברים לאחר לחיצה עליהם
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>קישורים בתחילת הדף המאפשרים דילוג לתוכן</span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    תיאור טקסטואלי לתמונות ואייקונים עבור טכנולוגיות מסייעות
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    התאמת האתר לסביבות עבודה ברזולוציות שונות (רספונסיביות)
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>כפתורי עצירה והפעלה של גלריות סרטונים</span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>הוטמעו חוקי </span>
                <span dir="ltr">
                    <span dir="ltr"></span>ARIA
                </span>
                <span>
                    <span dir="rtl"></span> העוזרים לפרש את תוכן האתר בצורה
                    מדויקת וטובה יותר
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    הנגשת תפריטים, טפסים ושדות, היררכיית כותרות, רכיבי טאבים,
                    חלונות קופצים ועוד
                </span>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <strong>
                    <span>שינוי תצוגה באתר</span>
                </strong>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>ניתן להגדיל או להקטין את תצוגת האתר באמצעות</span>
                <span>&#xa0; </span>
                <span>לחיצה על אחד מכפתורי ה- “</span>
                <span dir="ltr">
                    <span dir="ltr"></span>CTRL
                </span>
                <span>
                    <span dir="rtl"></span>” ביחד עם גלגלת העכבר או ביחד עם
                    הסימן “+” עבור הגדלה או ביחד עם הסימן “-” עבור הקטנת התצוגה.
                    כל לחיצה תקטין או תגדיל את המסך בעשרה אחוזים (10%)
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    שינוי גודל הגופן ייעשה באמצעות שימוש בתפריט הנגישות המצוי
                    באתר
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    גולשים אשר אין ברשותן עכבר או שאשאינם ינן יכולים לעשות שימוש
                    בעכבר יכולים להפעיל את התכונות המצויות באתר על ידי לחיצה על
                    המקש “
                </span>
                <span dir="ltr">
                    <span dir="ltr"></span>TAB
                </span>
                <span>
                    <span dir="rtl"></span>”. כל לחיצה תעביר את הסמן אל האפשרות
                    הבאה באתר
                </span>
                <br />
                <span>לחיצה על מקש ה- “</span>
                <span dir="ltr">
                    <span dir="ltr"></span>Enter
                </span>
                <span>
                    <span dir="rtl"></span>” תפעיל את הקישור עליו נמצא הסמן
                </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>
                    האתר אינו כולל הבהובים, ריצודים ותכנים בתנועה. במקומות אשר
                    נמצאים תכנים כאלה, ניתן לעצור אותם בעמידה עליהם ולחיצה על
                    העכבר או מעבר אליהם על ידי מקש ה- “
                </span>
                <span dir="ltr">
                    <span dir="ltr"></span>TAB
                </span>
                <span>
                    <span dir="rtl"></span>” ולחיצה על מקש ה- “
                </span>
                <span dir="ltr">
                    <span dir="ltr"></span>Enter
                </span>
                <span>
                    <span dir="rtl"></span>"
                </span>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <strong>
                    <span>התאמת אתר למוגבלי ראייה ושמיעה </span>
                </strong>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>מגדילי ראות (רזולוציה) בסיסיים </span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>תוכנות זיהוי קולי</span>
            </p>
            <p dir="rtl" className="mr-2">
                <span dir="rtl">
                    <span>●</span>
                </span>
                <span>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>
                <span>חבילות זיהוי קולי של מערכות ההפעלה </span>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <strong>
                    <span>סייגים לנגישות</span>
                </strong>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <span>
                    הנהלת האתר עושה ככל שניתן על מנת לוודא כי כלל הדפים המוצגים
                    יהיו מונגשים. יחד עם זאת, יתכן וישנם דפים שטרם הונגשו, או
                    שטרם נמצא פתרון טכנולוגי מתאים לצורך הנגשתם. בנוסף, ייתכן
                    ובמודעות חיצוניות, אשר הוכנסו על ידי בעלי עסקים המפרסמים
                    באתר, ההנגשה לא תהיה שלמה או מספקת.{" "}
                </span>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <strong>
                    <span>נתקלתם בבעיה? אנחנו כאן כדי לסייע! </span>
                </strong>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
            <p dir="rtl">
                <span>פרטי אחראי נגישות באתר :</span>
            </p>
            <p dir="rtl">
                <span>שם: עידן שילר</span>
            </p>
            <p dir="rtl">
                <span>אימייל: </span>
                <span dir="ltr">
                    <span dir="ltr"></span>is.chat.solutions@gmail.com
                </span>
            </p>
            <p dir="rtl">
                <span>מקור טופס הצהרת נגישות </span>
                <a href="https://rooks.co.il/">
                    <u>
                        <span>צריח מדיה</span>
                    </u>
                </a>
            </p>
            <p dir="rtl">
                <span dir="ltr">&#xa0;</span>
            </p>
        </div>
    );
}
