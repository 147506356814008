import { useMemo } from "react";

export default function useIsIphoneAndPWA() {
    const isIphoneAndPWA = useMemo(() => {
        const isStandalone = window?.matchMedia(
            "(display-mode: standalone)"
        ).matches;
        const isCoarsePointer = window?.matchMedia("(pointer: coarse)").matches;
        const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
        );
        return isStandalone && isCoarsePointer && isSafari;
    }, []);

    return isIphoneAndPWA;
}
