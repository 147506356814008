import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import Loader from "./components/Loader";
import { getUserData } from "./db-utils";
import { auth } from "./firebase";

function App() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        const navigateUser = async () => {
            if (user) {
                const userDoc = await getUserData({ userId: user.uid });
                if (!userDoc) {
                    navigate("/login");
                    return;
                }
                const { currentListId } = userDoc;
                navigate(`/list/${currentListId}`);
            } else if (!loading && !user && !error) {
                navigate("/login");
            }
        };
        navigateUser();
    }, [error, loading, navigate, user]);

    if (loading) return <Loader fullPage />;

    if (error) return <div>Error</div>;

    return <Loader fullPage />;
}

export default App;
