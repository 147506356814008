import Helmet from "react-helmet";

export default function Privacy() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://groceroo.co.il/privacy" />
            </Helmet>
            <strong>א. הקדמה</strong>
            <p></p>
            <ol>
                <li>
                    &nbsp;הפרטיות של המבקרים באתר שלנו חשובה לנו מאוד, ואנחנו
                    מחויבים לשמירה עליה. המדיניות הזו מסבירה מה נעשה עם הפרטים
                    האישיים שלכם.
                </li>
                <li>
                    ההסכמה לשימוש שלנו בעוגיות בהתאם לתנאים של מדיניות זו בזמן
                    הביקור הראשון באתר שלנו, מאפשרת לנו להשתמש בעוגיות בכל פעם
                    שאתם מבקרים באתר.
                </li>
            </ol>
            <strong>ב. קרדיט</strong>
            <br />
            המסמך הזה נוצר בעזרת תבנית של SEQ Legal (seqlegal.com) ושונתה על ידי
            Website Planet (
            <a href="https://www.websiteplanet.com">www.websiteplanet.com</a>)
            <p></p>
            <strong>ג. איסוף פרטים אישיים</strong>
            <p></p>
            ייתכן שהסוגים הבאים של פרטים אישיים ייאספו, יאוחסנו, ויעשה בהם
            שימוש:<p></p>
            <ol>
                <li>
                    מידע על המחשב שלכם, כולל כתובת ה-IP שלכם, מיקומכם הגאוגרפי,
                    סוג הדפדפן והגרסה שלו, ומערכת ההפעלה;
                </li>
                <li>
                    מידע על הביקורים והשימוש שלכם באתר, כולל מקור ההפניה, אורך
                    הביקור, צפיות בעמודים, ונתיבי המעבר שלכם באתר;
                </li>
                <li>
                    מידע, כמו למשל כתובת הדואר האלקטרוני שלכם, שאתם מזינים בזמן
                    ההרשמה לאתר שלנו;
                </li>
                <li>
                    מידע שאתם מזינים בזמן יצירת פרופיל באתר שלנו—לדוגמה, השם
                    שלכם, תמונות פרופיל, מגדר, יום הולדת, מצב מערכות יחסים,
                    תחומי עניין ותחביבים, פרטי השכלה, ופרטי תעסוקה;
                </li>
                <li>
                    מידע, כמו למשל השם וכתובת הדואר האלקטרוני שלכם, שאתם מזינים
                    על מנת ליצור מנוי להודעות הדואר האלקטרוני ו/או הניוזלטר
                    שלנו;
                </li>
                <li>המידע שאתם מזינים בזמן השימוש בשירותים באתר שלנו;</li>
                <li>
                    מידע שנוצר בזמן השימוש באתר שלנו, כולל מתי, כמה, ובאילו
                    נסיבות אתם משתמשים בו;
                </li>
                <li>
                    מידע שקשור לכל דבר שאתם רוכשים, שירותים בהם אתם משתמשים, או
                    העברות שאתם מבצעים באתר שלנו, הכולל את השם שלכם, הכתובת,
                    מספר הטלפון, כתובת הדואר האלקטרוני, ופרטי כרטיס האשראי שלכם;
                </li>
                <li>
                    מידע שאתם מפרסמים באתר שלנו בכוונה לפרסמו באינטרנט, שכולל את
                    שם המשתמש שלכם, תמונות הפרופיל, ותוכן התגובות שלכם;
                </li>
                <li>
                    מידע שנכלל במסרים שאתם שולחים לנו בדואר האלקטרוני או באמצעות
                    האתר שלנו, כולל תוכן המסר והמטא-דאתה שלו;
                </li>
                <li>כל סוג אחר של פרטים אישיים שאתם שולחים אלינו.</li>
            </ol>
            לפני שתחשפו בפנינו את הפרטים האישיים של אדם אחר, עליכם לבקש את הסכמת
            האדם הזה גם עבור השיתוף וגם עבור העיבוד של אותם הפרטים האישיים לפי
            מדיניות זו<p></p>
            <strong>ד. שימוש בפרטים האישיים שלכם</strong>
            <p></p>
            בפרטים אישיים שנמסרו לנו דרך האתר שלנו ייעשה שימוש למטרות שצוינו
            במדיניות זו, או בעמודים הרלוונטיים שבאתר. ייתכן שנשתמש בפרטים
            האישיים שלכם למטרות הבאות:<p></p>
            <ol>
                <li>ניהול האתר והעסק שלנו;</li>
                <li>התאמה האישית של האתר עבורכם;</li>
                <li>לאפשר לכם להשתמש בשירותים הזמינים באתר שלנו;</li>
                <li>שליחה של סחורות שרכשתם דרך האתר;</li>
                <li>אספקה של שירותים שרכשתם דרך האתר;</li>
                <li>
                    שליחה של הצהרות, חשבונות עסקה, ותזכורות תשלום אליכם, ואיסוף
                    תשלומים מכם.
                </li>
                <li>לשלוח לכם הודעות מסחריות שאינן שיווקיות;</li>
                <li>לשלוח לכם התראות בדואר אלקטרוני שביקשתם באופן ספציפי;</li>
                <li>
                    שליחה של הניוזלטר שלנו אליכם בדואר אלקטרוני, אם ביקשתם זאת
                    (אתם יכולים להודיע לנו בכל עת שכבר אין לכם צורך בניוזלטר);
                </li>
                <li>
                    שליחה של מסרים שיווקיים שקשורים לעסק שלנו והעסקים של חברות
                    צד ג’ שנבחרו בקפידה שלדעתנו עשויות לעניין אתכם, בדואר או,
                    במקרים שבהם הסכמתם לכך ספציפית, בדואר האלקטרוני, או באמצעות
                    טכנולוגיות דומות (אתם יכולים ליידע אותנו בכל עת אם אתם כבר
                    לא מעוניינים במסרים שיווקיים);
                </li>
                <li>
                    אספקה של מידע סטטיסטי בנוגע למשתמשים שלנו לצד ג’ (אבל צד ג’
                    זה לא יוכל לזהות אף משתמש בודד לפי המידע);
                </li>
                <li>
                    טיפול בבירורים ותלונות שהוגשו על ידכם או נוגעות אליכם
                    וקשורות לאתר שלנו;
                </li>
                <li>לשמור על האתר שלנו מאובטח ולמנוע הונאה;</li>
                <li>
                    אימות של היענות לתנאי השירות המכתיבים את אופן השימוש באתר
                    (כולל ניטור של הודעות פרטיות שנשלחו דרך שירות ההודעות
                    הפרטיות של האתר שלנו);
                </li>
                <li>ושימושים אחרים.</li>
            </ol>
            אם תמסרו לנו מידע אישי לפרסום באתר שלנו, אנחנו נפרסם את המידע ונשתמש
            בו בדרכים אחרות בהתאם לרישיון שתספקו לנו.<p></p>
            ניתן להשתמש בהגדרות הפרטיות שלכם כדי להגביל את הפרסום של המידע שלכם
            באתר שלנו, וניתן לשנות אותן בעזרת בקרות הפרטיות שבאתר.<p></p>
            אנחנו לא נספק את הפרטים האישיים שלכם ללא הסכמתכם לכל צד ג’ שהוא,
            לצורך השיווק הישיר שלו, או של כל צד ג’ אחר.<p></p>
            <strong>ה. חשיפת פרטים אישיים</strong>
            <p></p>
            אנחנו עשויים למסור את הפרטים האישיים שלכם לכל אחד מהעובדים, המנהלים,
            המבטחים, היועצים המקצועיים, הסוכנים, הספקים, או קבלני המשנה שלנו,
            במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.<p></p>
            אנחנו עשויים למסור את הפרטים האישיים שלכם לכל חברה בקבוצת החברות
            שלנו (זה אומר כל חברות הבת שלנו, חברת הגג שלנו, וכל חברות הבת שלה)
            במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.<p></p>
            אנחנו עשויים למסור את הפרטים האישיים שלכם:<p></p>
            <ol>
                <li>לפי מה שנדרש מאתנו על פי חוק;</li>
                <li>בהקשר של כל הליך משפטי קיים או עתידי;</li>
                <li>
                    על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל
                    אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון
                    אשראי);
                </li>
                <li>
                    לרוכש (או הרוכש הפוטנציאלי) של כל עסק או רכוש שאנחנו רוכשים
                    (או שוקלים לרכוש);
                </li>
                <li>
                    ולכל אדם שאנחנו מאמינים במידה סבירה שעשוי לפנות לבית דין או
                    לכל רשות מוסמכת לצורך מסירה של הפרטים האישיים בהם סביר
                    לדעתנו שאותו בית דין או רשות יורה על מסירה של פרטים אישיים
                    אלה.
                </li>
            </ol>
            למעט על פי הכתוב במדיניות זו, אנו לא נספק את הפרטים האישיים שלכם לצד
            ג’.<p></p>
            <strong>ו. העברות נתונים בינלאומיות</strong>
            <p></p>
            <ol>
                <li>
                    ייתכן שמידע שאנחנו אוספים יאוחסן, יעובד, ויועבר בין כל אחת
                    מהמדינות בהן אנו פועלים, על מנת לאפשר לנו להשתמש במידע בהתאם
                    למדיניות זו.
                </li>
                <li>
                    ייתכן שמידע שאנחנו אוספים יועבר למדינות הבאות, להן אין חוקי
                    הגנת נתונים התואמים את אלה הפועלים באזור הכלכלי האירופי:
                    ארצות הברית של אמריקה, רוסיה, יפן, סין, והודו.
                </li>
                <li>
                    פרטים אישיים שאתם מפרסמים באתר שלנו או מוסרים לפרסום באתר
                    שלנו עשויים להיות זמינים, באמצעות האינטרנט, מסביב לעולם.
                    אנחנו לא יכולים למנוע את השימוש או השימוש לרעה במידע הזה
                    בידי אחרים.
                </li>
                <li>
                    אתם מביעים הסכמה להעברות הפרטים האישיים המתוארות בסעיף ו’
                    זה.
                </li>
            </ol>
            <strong>ז. שמירת פרטים אישיים</strong>
            <p></p>
            <ol>
                <li>
                    סעיף ג’ זה מפרט את תהליך ומדיניות שמירת הנתונים שלנו,
                    המתוכננים לעזור להבטיח שאנחנו נענים לחובות המשפטיות שלנו
                    הנוגעות לשמירה ולמחיקה של פרטים אישיים.
                </li>
                <li>
                    פרטים אישיים שאנחנו מעבדים עבור כל מטרה או מטרות, לא יישמרו
                    יותר מכמה שנדרש עבור מטרה או מטרות אלה.
                </li>
                <li>
                    מבלי לגרוע מן האמור בסעיף ז-2, לרוב אנו נמחק נתונים אישיים
                    הנמצאים בקטגוריות המפורטות מטה בתאריך/שעה המפורטים מטה:
                    <ol type="a">
                        <li>סוג הנתונים האישיים יימחק 01.01.2025;</li>
                        <li>&nbsp;ו-01.01.2026.</li>
                    </ol>
                </li>
                <li>
                    על אף ההוראות האחרות בסעיף ז’, אנו נשמור מסמכים (כולל מסמכים
                    אלקטרוניים) המכילים נתונים אישיים:
                    <ol type="a">
                        <li>לפי מה שנדרש מאתנו על פי חוק;</li>
                        <li>
                            אם לדעתנו המסמכים עשויים להיות רלוונטיים לכל הליך
                            משפטי מתמשך או פוטנציאלי;
                        </li>
                        <li>
                            על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות
                            (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה
                            של סיכון אשראי).
                        </li>
                    </ol>
                </li>
            </ol>
            <strong>ח. אבטחת הפרטים האישיים שלכם</strong>
            <p></p>
            <ol>
                <li>
                    אנחנו ננקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את
                    האבדן, השימוש לרעה, או השינוי של הפרטים האישיים שלכם.
                </li>
                <li>
                    אנחנו נאחסן את כל הפרטים האישיים שאתם מספקים בשרתים מאובטחים
                    (המוגנים בסיסמא ובחומת אש).
                </li>
                <li>
                    כל ההעברות הכספיות הממוחשבות שבוצעו דרך האתר שלנו יהיו
                    מוגנות באמצעות טכנולוגיית הצפנה.
                </li>
                <li>
                    אתם מכירים בכך שהשידור של מידע על פני האינטרנט אינו מאובטח
                    מעצם היותו, ואנחנו ללא יכולים להבטיח שנתונים שנשלחים דרך
                    האינטרנט יהיו מאובטחים.
                </li>
                <li>
                    אתם אחראים על שמירת הסיסמא בה אתם משתמשים לקבלת גישה לאתר
                    שלנו חסויה; אנחנו לא נבקש מכם את הסיסמא שלכם (למעט בזמן
                    ההתחברות לאתר שלנו).
                </li>
            </ol>
            <strong>ט. תיקונים</strong>
            <p></p>
            אנחנו עשויים לעדכן מדיניות זו מעת לעת באמצעות פרסום של גרסה חדשה
            באתר שלנו. עליכם לבדוק את העמוד הזה מדי פעם על מנת לוודא שאתם מבינים
            כל שינוי שנעשה במדיניות זו. אנחנו עשויים להודיע לכם על שינויים
            במדיניות זו בהודעת דואר אלקטרוני או דרך מערכת ההודעות הפרטיות שבאתר
            שלנו.<p></p>
            <strong>י. הזכויות שלכם</strong>
            <p></p>
            אתם יכולים להורות לנו לספק לכם כל פרט מפרטיכם האישיים שאנו מחזיקים
            הנוגעים אליכם; אספקת פרטים אלה תהיה כפופה לתנאים הבאים:<p></p>
            <ol>
                <li>תשלום של עמלה 2000 ש"ח;</li>
                <li>אספקה של ראיות הולמות עבור הזהות שלכם .</li>
            </ol>
            אנחנו עשויים לסרב למסור פרטים אישיים שאתם מבקשים עד למידה המורשית על
            פי חוק.<p></p>
            אתם יכולים להורות לנו בכל עת לא לעבד את הפרטים האישיים שלכם לצורכי
            שיווק.<p></p>
            בפועל, בדרך כלל תבטאו הסכמה מראש לשימוש שלנו בפרטים האישיים שלכם
            למטרות שיווק, או שאנחנו נספק לכם אפשרות לבחור שלא יעשה בפרטים
            האישיים שלכם שימוש למטרות שיווקיות.
            <br />
            יא. אתרים צד ג’<p></p>
            האתר שלנו כולל קישורים חיצוניים, ופרטים על, אתרים צד ג’. אין לנו
            שליטה על, ואיננו אחראים על, מדיניות הפרטיות והנהלים של כל צד ג’.
            <p></p>
            <strong>יא. אתרים צד ג’</strong>
            <p></p>
            האתר שלנו כולל קישורים חיצוניים, ופרטים על, אתרים צד ג’. אין לנו
            שליטה על, ואיננו אחראים על, מדיניות הפרטיות והנהלים של כל צד ג’.
            <p></p>
            <strong>יב. עדכון מידע</strong>
            <p></p>
            אני הודיעו לנו אם יש צורך לתקן או לעדכן את הפרטים האישיים שאנו
            מחזיקים עליכם.<p></p>
            <strong>יג. עוגיות</strong>
            <p></p>
            האתר שלנו משתמש בעוגיות. עוגייה היא קובץ המכיל מזהה (מחרוזת של
            אותיות ומספרים) שנשלח על ידי שרת אינטרנט לדפדפן אינטרנט ומאוחסן
            בדפדפן. אז, המזהה נשלח בחזרה לשרת בכל פעם שהדפדפן מבקש מהשרת להציג
            דף אינטרנט. עוגיות יכולות להיות או עוגיות “עיקשות” או עוגיות “פעולה”
            (session): עוגייה עיקשת תאוחסן בדפדפן שלכם ותישאר בתוקף עד תאריך
            התפוגה שנקבע לה, אלא אם תימחק על ידי המשתמש לפני תאריך התפוגה;
            עוגיית פעולה, מאידך, תפוג בסוף זמן הגלישה הנוכחי שלכם, כשתסגרו את
            הדפדפן. בדרך כלל עוגיות לא כוללות מידע שמזהה משתמשים אישית, אבל
            פרטים אישיים שאנחנו מאחסנים הנוגעים עליכם יכולים להיות מקושרים למידע
            המאוחסן והמתקבל מתוך עוגיות.<p></p>
            <ol>
                <li>
                    השמות של העוגיות בהן אנחנו משתמשים באתר שלנו, והמטרות לשמן
                    הן בשימוש, מפורטות מטה:
                    <ol type="a">
                        <li>
                            אנחנו משתמשים בשירותי Google Analytics ו-Adwords
                            באתר שלנו כדי לזהות מחשב כשמשתמש;
                        </li>
                    </ol>
                </li>
                <li>
                    רוב האתרים מאפשרים לכם לסרב לשימוש בעוגיות—למשל:
                    <ol type="a">
                        <li>
                            באינטרנט אקספלורר (גרסה 10) אתם יכולים לחסום עוגיות
                            בעזרת הגדרות עקיפת הטיפול בעוגיות הזמינות באמצעות
                            לחיצה על “כלים,” “אפשרויות אינטרנט,” “פרטיות,” ואז
                            “מתקדם”;
                        </li>
                        <li>
                            בפיירפוקס (גרסה 24) אתם יכולים לחסום את כל העוגיות
                            באמצעות לחיצה על “כלים,” “אפשרויות,” “פרטיות,”
                            ובחירה של “השתמש בהגדרות מותאמות אישית עבור
                            היסטוריה” מתוך התפריט הנפתח, ואז ביטול הזימון של
                            “קבל עוגיות מאתרים”;
                        </li>
                        <li>
                            ובכרום (גרסה 29), אתם יכולים לחסום את כל העוגיות
                            באמצעות כניסה לתפריט “התאמה אישית ובקרה,” ואז ללחוץ
                            על “הגדרות,” “הצג הגדרות מתקדמות,” ו-“הגדרות תוכן,”
                            ואז לבחור באפשרות “חסום אתרים מהגדרה של נתונים” תחת
                            הכותרת “עוגיות.”
                        </li>
                    </ol>
                </li>
            </ol>
            לחסימה של כל העוגיות תהיה השפעה שלילית על נוחות השימוש של אתרים
            רבים. אם תחסמו עוגיות, לא תוכלו להשתמש בכל האפשרויות שבאתר שלנו.
            <p></p>
            <ol>
                <li>
                    אתם יכולים למחוק עוגיות שכבר מאוחסנות במחשב שלכם—למשל:
                    <ol type="a">
                        <li>
                            בדפדפן אינטרנט אקספלורר (גרסה 10), אתם צריכים למחוק
                            קבצי עוגיות ידנית (תוכלו למצוא הוראות לכך בכתובת{" "}
                            <a href="http://support.microsoft.com/kb/278835">
                                http://support.microsoft.com/kb/278835
                            </a>{" "}
                            );
                        </li>
                        <li>
                            בפיירפוקס (גרסה 24), אתם יכולים למחוק עוגיות באמצעות
                            לחיצה על “כלים,” “אפשרויות,” ו-“פרטיות”, ואז לבחור
                            “השתמש בהגדרות מותאמות אישית עבור היסטוריה”, וללחוץ
                            על “הצג עוגיות,” ואז “הסר את כל העוגיות”;
                        </li>
                        <li>
                            ובכרום (גרסה 29), אתם יכולים למחוק את כל העוגיות
                            באמצעות כניסה לתפריט “התאמה אישית ובקרה,” ואז ללחוץ
                            על “הגדרות,” “הצג הגדרות מתקדמות,” ו-“נקה נתוני
                            גלישה,” ואז לבחור באפשרות “מחק עוגיות ונתונים אחרים
                            של אתרים ותוספים,” ולבסוף ללחוץ “נקה נתוני גלישה.”
                        </li>
                    </ol>
                </li>
                <li>
                    למחיקה של עוגיות תהיה השפעה שלילית על נוחות השימוש של אתרים
                    רבים.
                </li>
            </ol>
            <em>
                Website Planet לא לוקח אחריות וממליץ לכם להתייעץ עם מומחים
                משפטיים אם אתם משתמשים בתבנית לעיל באתר שלכם.
            </em>
            <p></p>
        </div>
    );
    return (
        <div>
            <div className="my-4 flex flex-col gap-5">
                <h1 className="text-center text-3xl">מדיניות פרטיות</h1>
                <h2 className="text-2xl font-medium">מדיניות הגנת הפרטיות</h2>
                <p>
                    הגעתם לאתר Groceroo (גרוסרו) . יש לקרוא בקפידה את מדיניות
                    הגנת הפרטיות המפורטת להלן (להלן – "מדיניות הפרטיות"). הגלישה
                    והשימוש שתעשה באתר זה{" "}
                    <a
                        href="https://groceroo.app"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="underline text-blue-500"
                    >
                        https://groceroo.app
                    </a>{" "}
                    (להלן – "האתר") כפופה למדיניות הפרטיות. במידה ואינך מסכים
                    לאמור במדיניות הפרטיות במלואה וללא סייג, אנא הפסק מיד את
                    גלישתך באתר.{" "}
                </p>
                <h2 className="text-2xl font-medium">כללי</h2>
                <p>
                    {" "}
                    1. גרוסרו (להלן – "החברה") מתייחס בכבוד לפרטיות המשתמש באתר
                    משתמש או מבקר באתר (להלן – "המשתמש"). מדיניות הפרטיות
                    המפורטת להלן מתייחסת לסוג המידע שהחברה תהא רשאית לאסוף או
                    למסור ו/או לחשוף, כמו גם את הדרך ומטרת עיבוד מידע זה.
                    מדיניות הפרטיות אף תורה ותנחה את המשתמש כיצד לנהוג במידה
                    שברצונו כי פרטיו האישיים לא ייאספו או יחשפו ו/או ימסרו
                    לאחרים כאשר הוא משתמש או מבקר באתר. <br /> 2. החברה רשאית,
                    בכל עת, לשנות את מדיניות הפרטיות על ידי עדכונו של מסמך זה,
                    ושינוי זה יחייב את המשתמש לכל דבר ועניין.{" "}
                </p>
                <h2 className="text-2xl font-medium">ניהול המידע</h2>
                <p>
                    {" "}
                    1. נדרשת הרשמה על מנת להשתמש בשירותי האתר. במסגרת ההרשמה
                    תידרש למסור מידע,כדוגמת שמך, מספר טלפון שלך, דרכי ההתקשרות
                    עמך או כתובת הדואר האלקטרוני שלך, והכל על מנת לקבל את
                    השירותים שמציע האתר ומידע נוסף. <br /> 2. החברה מספקת את
                    שירותיה בעזרת שימוש באפליקציית המסרים “ווצאפ” (WhatsApp)
                    בצ’אט פרטי בלבד. תכני התכתובות ישמרו במאגר הנתונים של החברה.{" "}
                    <br /> 3. החברה רשאית לנהל מאגר מידע ולעבד כל נתון על
                    המשתמשים (כגון שם, מספר טלפון נייד, כתובת דואר אלקטרוני,
                    וכיו"ב (להלן – "מידע אישי" או "פרטים אישיים") אשר יגיעו
                    לידיעת החברה במסגרת השימוש באתר ו/או השירות באפליקציית
                    הווצאפ ושהועברו על ידי המשתמשים, על פי בחירתם החופשית. החברה
                    מתחייבת כי ככל שיהיה לה מאגר מידע כאמור, השימוש בו ואופן
                    ניהולו יהיו בהתאם להוראות דיני מדינת ישראל שיחולו לאותו
                    עניין. <br /> 4. החברה עשויה להעביר את המידע האישי לחברות
                    הקשורות אליה ובתנאי שהדבר יהיה נחוץ למטרות תפעוליות, לסליקת
                    תשלום שבוצע על ידי המשתמשים וניהול חשבונות המשתמשים, ליצירת
                    קשר טוב יותר עם המשתמש, למטרות סטטיסטיקות, ולצרכי דיוור
                    ישיר, לרבות משלוח הודעות טקסט והודעות דואר אלקטרוני בקשר עם
                    שירותי החברה. כמו כן, יתכן והחברה תעביר מידע אישי לצדדים
                    שלישיים אשר משתפים פעולה עם החברה לשם ביצוע המטרות המנויות
                    לעיל. <br /> 5. אם משתמש אינו מעוניין שמידע אישי שלו ייאסף,
                    יימסר או ייחשף באופן כלשהו כמתואר לעיל, עליו להימנע מהעברת
                    פרטיו האישיים לחברה כאמור או להסיר את עצמו מרשימת השירותים
                    הדיגיטליים. <br /> 6. משתמש באתר אשר הסכים לתנאים שתוארו
                    לעיל לגבי איסוף, אחסון וחשיפת פרטיו האישיים בהתאם להוראות
                    אלה, רשאי\ת לממש כל זכות השמורה לו במסגרת הדין. <br /> 7.
                    במקרה ועל החברה יוטל צו שיפוטי המורה לה לאסוף או למסור ו/או
                    לחשוף את פרטיו האישיים של משתמש באתר, לא תהיה לחברה כל חבות
                    ו/או אחריות כלפי אותו משתמש או מטעמו. <br />
                </p>
                <h2 className="text-2xl font-medium">אתרי צד שלישי</h2>
                <p>
                    {" "}
                    האתר מכיל קישורים לאתרים של צדדים שלישיים אשר אינם בשליטת
                    החברה. מדיניות הפרטיות אינה חלה באתרים אלו והחברה אינה
                    אחראית למדיניות הפרטיות הנהוגה באותם אתרים. החברה אינה
                    אחראית לנוהלי הגנת הפרטיות או לתנאי השימוש הנהוגים באתרים
                    אלו.{" "}
                </p>
                <h2 className="text-2xl font-medium">ברירת דין ומקום שיפוט</h2>
                <p>
                    {" "}
                    על מדיניות הפרטיות יחולו אך ורק דיני מדינת ישראל, מקום
                    השיפוט הבלעדי לכל עניין הנוגע למדיניות הגנת הפרטיות באתר
                    הינו בבתי המשפט המוסמכים. תנאי השימוש באתר מנוסחים בלשון זכר
                    לצרכי נוחות בלבד, והם מתייחסים כמובן גם לנשים.{" "}
                </p>
            </div>
        </div>
    );
}
